export const Products = [
{
    _id: 'ghbhg66t88',
    title: 'XIAOMI Redmi',
    price: 55000,
    brand: 'XIAOMI',
    color: 'Black',
    description: 'XIAOMI Redmi 12C 6.71" 3GB RAM/64GB ROM Android 12 - Graphite Grey',
    location: 'Unilag Main Gate',
    stock: 10,
    person: 'store',
    condition: 'New',
    age: '',
    fault: 'None',
    posted: '1 day ago',
    image_1: 'https://res.cloudinary.com/dneejvhch/image/upload/v1698058939/Design_Portfolio/app1splashscreen_kz1sij.png',
    image_2: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701676455/Campusbuy/1_g9yf9o.jpg',
    image_3: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701676455/Campusbuy/1_1_fjtmzn.jpg',
    details: 'Brand new phone with 1 year warranty',
},

{
    _id: '09878ygjkk',
    title: 'IPhone XS',
    price: 195000,
    brand: 'Apple',
    color: 'Gold',
    description: 'Apple iPhone XS 64 GB Black',
    location: 'Lasu Auditorium',
    stock: 1,
    person: 'individual',
    condition: 'Used',
    age: '1 Year',
    fault: 'None',
    posted: '2 hours ago',
    image_1: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701676466/Campusbuy/105030016_MTEyNS0xNTAwLTdhOTUzNDZkYTA_kgc4ov.webp',
    image_2: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701676471/Campusbuy/105030020_MTEyNS0xNTAwLTg5M2VhYWFhZTg_gj9utg.webp',
    image_3: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701424576/Campusbuy/137653049_MTUwMC0yMDAwLWU3ODRiYjcwYzI_ukbeun.webp',
    details: 'Fairly used phone with 6 months warranty',
},

{
    _id: 'dddddede22',
    title: 'Toshiba Satellite X-500',
    price: 225000,
    brand: 'Toshiba',
    color: 'Asphalt Black',
    description: 'Toshiba Satellite X-500 15.6" 4GB RAM/500GB HDD Intel Core i7 - Black',
    location: 'YabaTech Main Hostel',
    stock: 2,
    person: 'individual',
    condition: 'Used',
    age: '6 months',
    fault: 'None',
    posted: '2 weeks ago',
    image_1: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701426246/Campusbuy/lenovo-laptops-thinkbook-16-gen-4-intel-hero_tngndc.webp',
    image_2: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701676456/Campusbuy/44303277_20200504-173441_1_1500x1125_judbbl.webp',
    image_3: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701676455/Campusbuy/44303267_20200504-173416_1_1500x1125_jamstg.webp',
    details: 'Healthy Toshiba laptop with 6 months warranty',
},

{
    _id: 'ddfffede22',
    title: 'Elepaq X3kv 500 Generator',
    price: 260000,
    brand: 'Elepaq',
    color: 'Crimson Red',
    description: 'Maxi Portable Elepaq X3kv Key Start Generator (4.8kva)',
    location: 'Uniport  Anglican Chapel Auditorium',
    stock: 1,
    person: 'individual',
    condition: 'Used',
    age: '8 months',
    fault: 'None',
    posted: '2 days ago',
    
    image_1: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701686120/Campusbuy/127026138_MTUwMC0xNTAwLTlhNjcyMDQxNjctMQ_voap36.webp',
    image_2: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701686120/Campusbuy/117743820_ODEwLTEwODAtNDZkMmU5NmRjNw_qis1xp.webp',
    image_3: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701424576/Campusbuy/80472425_MTM2NS0xNTAwLWNkNmMxMGRiZWUtMQ_jy1xxw.webp',
    details: 'Belgium used Elepaq Generator with solid engine and 6 months warranty',
},

{
    _id: 'ddfffese11',
    title: 'Hisense Single Door Refridgerator RS20S',
    price: 240000,
    brand: 'Hisense',
    color: 'Silver',
    description: 'Modern Hisense `150 litres Single Door Refridgerator RS20S',
    location: 'Auchi Ploytechnic  Anglican Chapel Auditorium',
    stock: 1,
    person: 'individual',
    condition: 'New',
    age: '',
    fault: 'None',
    posted: '3 weeks ago',
    
    image_1: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701686384/Campusbuy/118707172_MTEyNS0xNTAwLTVjYmJkNDk0MGM_nio3ii.webp',
    image_2: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701686384/Campusbuy/118707032_NzIxLTEyODAtOGUwN2VhOWNjMw_cvis7e.webp',
    image_3: 'https://res.cloudinary.com/dneejvhch/image/upload/v1701686308/Campusbuy/75882006_ODA4LTEwODAtMDczOWNkYmI5Ny0z_b7gwis.webp',
    details: 'Brand New  Hisesnse 150 litres Freezer with 8 months warranty',
},



]

export const Mobile = [
    {
        title: 'Apartment',
        image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/6879751_xbhcsd.png',
        API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    
    {
        title: 'Cars',
        image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/744465_mlwhnj.png',
        API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
        title: 'Furnitures',
        image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/10001869_zqxjus.png',
        API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Watches",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/9098010_nlgbu5.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Games",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740074864/5930147_bxnfab.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Generator",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/5673405_qknri5.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Tv",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/1170586_wgreti.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Electronics",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/1555401_wqnwya.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Beds",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/8948962_twhnq0.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Clothes",
    image: 'https://res.cloudinary.com/dtthdh8tb/image/upload/v1740073930/925072_pvzb2y.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Chairs",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051052/Default/images_17_mvkpvl.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Plastics",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051051/Default/images_16_e7oy8z.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Tables",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051051/Default/images_15_cqw5p0.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Bags",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051047/Default/images_14_supjso.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Hairs",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051046/Default/images_13_xj6y67.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Attachments",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051046/Default/images_12_x9cidd.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Wigs",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051046/Default/images_13_xj6y67.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "handbag",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051041/Default/images_10_pls42p.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Chains",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051087/Default/P11212867-25-1_jvwq96.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Jewelries",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051041/Default/images_9_a9xe0f.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Shoes",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051040/Default/images_8_nj6pvl.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Footwears",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051040/Default/images_7_qkyo4z.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Foods",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051037/Default/images_6_kxz8vr.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Snacks",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051033/Default/images_5_kwlqrm.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Cooker",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051033/Default/images_4_t5icjj.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Cylinders",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051034/Default/buy-cylinders_iu7zvf.png',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Textbooks",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051033/Default/images_3_jasi0l.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Materials",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051033/Default/images_2_suhp6k.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
    {
    
    title: "Tutorials",
    image: 'https://res.cloudinary.com/djj8xwuzn/image/upload/v1707051032/Default/download_wlnhkb.jpg',
    API:    'https://campusbuy-backend.onrender.com/getproducts?',
    },
];