import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NavLink, Link} from 'react-router-dom';
import {Secondheader} from '../Components/Subheaders';
import Footer from '../Components/Footer'
import { schools } from '../Constants/Universities';
import {Minifooter} from '../Components/Subheaders';
import { ColorRing  } from 'react-loader-spinner';
import {Helmet} from 'react-helmet';
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { Pricetips, Purchasetips, Bulkgoodstips } from '../Modals/Tips';
import { Bigloader } from '../Modals/Loaders';
import { Useralreadyexists, Userdoesnotexist, Resetpasswordlinkexpired, Incorrectpassword,Loginsuccessful,
         Createaccountsuccessful, Resetpasswordemail, Resetpasswordsuccessful, Createaccountmobileerror, Createaccountmobile2error } from '../Modals/Pages/Signin';

        


export const Signup = () => {
  const navigate = useNavigate();
  const [createAccountModals, setCreateAccountModals] = useState(false);
  const [createAccountErrorModals, setCreateAccountErrorModals] = useState(false);
  const [mobileErrorModal, setMobileErrorModal] = useState(false);
  const [mobile2ErrorModal, setMobile2ErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [activeTip, setActiveTip] = useState(null);
  const [createAccountOrAds, setCreateAccountOrAds]= useState(true);

  const CREATE_USER_API_KEY = 'https://campusbuy-backend.onrender.com/createuser';

  const mainBG = {
    backgroundImage: '  url("https://res.cloudinary.com/dtthdh8tb/image/upload/v1728300443/Rectangle_242_k4pexq.png")   ',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%'
  };

  const modal = {
    position: 'fixed',
    top: '10%',
    right: '5%',
    zIndex: '4000',
    width: '60%,'
  }

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#F6BD63',
      fontWeight: 'bold',
    }),
    control: (provided) => ({
      ...provided,
      borderColor: 'black',
      borderWidth: 2,
      borderRadius: '0.375rem',
      backgroundColor: '#F6BD63',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#F6BD63',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FEBD69' : '#F6BD63',
      color: 'black',
      '&:hover': {
        backgroundColor: '#FFD700',
      },
    }),
  };

  const SignupSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
      .matches(/^[A-Za-z0-9]+$/, 'Password can only contain letters and numbers')
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    mobile: Yup.string()
      .matches(/^[0-9]{11}$/, 'Mobile number must be exactly 11 digits and contain only numbers.')
      .required('Mobile number is required'),
    mobile2: Yup.string()
      .matches(/^[0-9]{11}$/, 'Mobile number must be exactly 11 digits and contain only numbers.')
      .required('Mobile number is required'),
    university: Yup.string().required('University is required'),
    sex: Yup.string().required('Gender is required'),
    address: Yup.string().required('Address is required'),
});

  const openCreateAccountModal = () => {
    setCreateAccountModals(true);
  };
  const closeCreateAccountModal = () => {
    setCreateAccountModals(false);
  };
  const openCreateAccountErrorModal = () => {
    setCreateAccountErrorModals(true);
  };
  const closeCreateAccountErrorModal = () => {
    setCreateAccountErrorModals(false);
  };
  const openMobileErrorModal = () => {
    setMobileErrorModal(true);
  };
  const closeMobileErrorModal = () => {
    setMobileErrorModal(false);
  };
  const openMobile2ErrorModal = () => {
    setMobile2ErrorModal(true);
  };
  const closeMobile2ErrorModal = () => {
    setMobile2ErrorModal(false);
  };
  const openLoading = () => {
    setLoading(true);
  };
  const closeLoading = () => {
    setLoading(false);
  };

  const showPassword = () => {
    setPasswordVisibility(true);
  };

  const hidePassword = () => {
    setPasswordVisibility(false);
  };

  const togglePassword = () => {
    if (passwordVisibility === false) {
      showPassword();
    } else {
      hidePassword();
    }
  };

  const renderComponent = () => {
    switch (passwordVisibility) {
      case true:
        return <MdOutlineVisibilityOff />;
      case false:
        return <MdOutlineVisibility />;
      default:
        return null;
    }
  };

  const tipsArray = ['Pricetips', 'Purchasetips', 'Bulkgoodstips'];
  const getRandomTip = () => tipsArray[Math.floor(Math.random() * tipsArray.length)];

  useEffect(() => {
    const displayRandomTip = () => {
      const randomTip = getRandomTip();
      setActiveTip(randomTip);
    };

    const displayTipTimeout = setTimeout(() => {
      displayRandomTip();
    }, 50000);

    const changeTipInterval = setInterval(() => {
      displayRandomTip();
    }, 600000);

    return () => {
      clearTimeout(displayTipTimeout);
      clearInterval(changeTipInterval);
    };
  }, []);

  let tipComponent = null;

  if (activeTip === 'Pricetips') {
    tipComponent = <Pricetips closeTips={() => setActiveTip(null)} />;
  } else if (activeTip === 'Purchasetips') {
    tipComponent = <Purchasetips closeTips={() => setActiveTip(null)} />;
  } else if (activeTip === 'Bulkgoodstips') {
    tipComponent = <Bulkgoodstips closeTips={() => setActiveTip(null)} />;
  }

  
  const createAccount = async (values, { setSubmitting }) => {
    try {
      openLoading();
      const response = await axios.post(CREATE_USER_API_KEY, values);
      if (response.data) {
        localStorage.setItem('userData', JSON.stringify(response.data));
        console.log(response.data)
        setTimeout(() => {
          localStorage.removeItem('userData');
        }, 24 * 60 * 60 * 1000);
  
        openCreateAccountModal();
        setTimeout(() => {
          closeCreateAccountModal();
          navigate('/');
        }, 5000);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        openCreateAccountErrorModal();
        setTimeout(() => {
          closeCreateAccountErrorModal();
          navigate('/signin');
        }, 4000);
      }  else if (error.response && error.response.status === 411) {
        openMobileErrorModal();
      } else if (error.response && error.response.status === 412) {
        openMobile2ErrorModal();
      }  else {
        alert('Connection error. Please refresh your network');
      }
    } finally {
      closeLoading();
      setSubmitting(false);
    }
  };

  const openAds = (e) => {
    // Open the external ad link in a new tab using window.open()
    setCreateAccountOrAds(false)
    window.open('https://psolsumoo.net/4/8022756', '_blank');
  };
  
  

  const sexOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
    { value: 'preferNotToSay', label: 'Prefer not to say' },
  ];

  return (

    <div className='' style={mainBG}>
       <Helmet>
   {/* Other meta tags */}
   <meta property="og:url" content="https://campusify.net/signin" />
   <meta property="og:title" content="Signin: Join the student market on campus" />
   <meta
     property="og:description"
     content="The largest student-to-student online marketplace in Nigeria. Buy and sell anything from your fellow students. Textbooks, electronics, clothes, and more! Safe and secure platform with guaranteed delivery."
   />
   <meta property="og:image" content="https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298881/IMG-20240227-WA0015_wdparn.jpg" />
  {/* Add other Open Graph meta tags like og:site_name */}

    <meta property="og:title" content="Campusify" />
    <meta property="og:description" content="The largest student-to-student online marketplace in Nigeria. Buy and sell anything from your fellow students. Textbooks, electronics, clothes, and more! Safe and secure platform with guaranteed delivery." />
    <meta property="og:image" content="" />
    <meta property="og:url" content="https://campusify.net/signin" />
    <meta property="og:type" content="website" />

    
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Campusify" />
    <meta name="twitter:description" content="The largest student-to-student online marketplace in Nigeria. Buy and sell anything from your fellow students. Textbooks, electronics, clothes, and more! Safe and secure platform with guaranteed delivery." />
    <meta name="twitter:image" content="" />
    </Helmet>
    <div className='w-[15%]  max-lg:w-[20%] all-round-tips rounded-lg bg-[rgba(255, 255, 255, 0.8)]'>
  {tipComponent}
</div>
       <div className=''><Secondheader/></div>

<div className='max-lg:hidden w-[100%]'>
<div className='flex justify-between'>
<div className='bg-[#0C0908] w-[50%] py-[1rem] px-[2rem] text-white mt-[10rem] '>
<div className='flex justify-end items-center m-1 gap-20 mr-4'>
  <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={60} alt='logo'/></NavLink>
  <p className=' text-[12px] text-center'>Already have an account? <NavLink to='/signin'><strong>Sign in</strong></NavLink></p>
</div>
<div className='text-center m-1'>
  <h1 className=' text-xl m-2'><strong>Welcome to Campusify</strong></h1>
  <p className=' text-sm'>Sign up today and unlock a world of possiblities. Your adventure begins here.</p>
</div>
<div className='flex flex-col gap-1 justify-center items-center'>
<Formik
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                mobile: '',
                mobile2: '',
                university: '',
                sex: '',
                address: ''
              }}
              validationSchema={SignupSchema}
              onSubmit={createAccount}
            >
                {({ isSubmitting }) => (

 <Form  className='p-1  w-full flex flex-col gap-1.5 mt-[1rem]'>
  
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='First Name'><strong>First Name</strong></label>
     <Field type='text' name='firstname' className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='First Name'/>
     <ErrorMessage name='firstname' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Last Name'><strong>Last Name</strong></label>
     <Field type='text' name='lastname' className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Last Name'/>
       <ErrorMessage name='lastname' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Email Address'><strong>Email Address</strong></label>
     <Field type='text' name='email' autoComplete="off" className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Email Address'/>
       <ErrorMessage name='email' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px]  '>
     <label className='text-bold' htmlFor='Password'><strong> Password</strong></label>
     <div className='relative rounded-[6px]  signup-border bg-[#F6BD63] text-black '>
     <Field type={passwordVisibility? "text" : "password" } name='password' className='p-2 w-[100%] rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Password'/>
     <div style={{left: "85%", top: "40%"}} className='absolute' onClick={togglePassword}>{renderComponent()}</div>
     </div>
     <ErrorMessage name='password' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Phone Number'><strong>Phone Number</strong></label>
     <Field type='text' name='mobile' autoComplete="off" className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Phone Number'/>
       <ErrorMessage name='mobile' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Phone Number'><strong>Phone Number 2</strong></label>
     <Field type='text' name='mobile2' autoComplete="off" className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Second Phone Number'/>
       <ErrorMessage name='mobile2' component='div' className='text-red-500' />
   </div>
   <div className="col-span-2">
  <label className="font-bold">University</label>
  <Field name="university">
    {({ field, form }) => (
      <Select
        options={schools}
        styles={customStyles}
        placeholder="Select School"
        isClearable
        value={schools.find(option => option.value === field.value) || null}
        onChange={(option) => form.setFieldValue(field.name, option ? option.value : '')}
      />
    )}
  </Field>
  <ErrorMessage name="university" component="div" className="text-red-500 text-sm" />
</div>



<div className="col-span-2">
  <label className="font-bold">Gender</label>
  <Field name="sex">
    {({ field, form }) => (
      <Select
        options={sexOptions}
        styles={customStyles}
        placeholder="Select Gender"
        value={sexOptions.find(option => option.value === field.value) || null}
        onChange={(option) => form.setFieldValue(field.name, option ? option.value : '')}
      />
    )}
  </Field>
  <ErrorMessage name="sex" component="div" className="text-red-500 text-sm" />
</div>

                  <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Address'><strong>Address</strong></label>
     <Field type='text' name='address' className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Address'/>
     <ErrorMessage name='address' component='div' className='text-red-500' />
   </div>
   
   <NavLink to='/forgotpassword' disabled={isSubmitting} className='text-[12px]'>Forgot Password ?</NavLink>

   <button type="submit" className='p-2 inline-block w-full rounded-[6px] mt-2 bg-black border-2 border-[#FFD700] text-[#FFB04A]'>Submit</button>
 </Form>
)}
</Formik>
 </div>
 <div className='text-xl text-center my-5'><Link  to='/contact'><strong>Send us a Message</strong></Link></div>
 </div>
 <div className='w-[50%] text-center p-2'>
   <div className=' text-white  mt-[12rem]'>
     <h1 className=' text-2xl m-4'><strong>Campusify</strong></h1>
     <p className='my-2'>For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc. Campusify is the place you need to be to find these things</p>
   </div>
   <img src='https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png' alt='model'
 />
 </div>
 </div>


 <div  style={{position: "fixed", top: "50%", left:"50%"}}>
  {loading && <div className='loading-modal flex flex-col justify-center items-center'>    
  <p style={{ color: 'white', marginTop: '10px' }}>Creating account. Please wait...</p>
 <div className='w-[70%] my-[6rem] ml-[6rem]'><Bigloader /></div>
   </div>}
 </div>
   {createAccountModals && <div style={modal}><Createaccountsuccessful  closeModal={closeCreateAccountModal}/></div>}
   {createAccountErrorModals && <div style={modal}><Useralreadyexists closeModal={closeCreateAccountErrorModal}/></div>}
   {mobileErrorModal && <div style={modal}><Createaccountmobileerror closeModal={closeMobileErrorModal}/></div>}
   {mobile2ErrorModal && <div style={modal}><Createaccountmobile2error closeModal={closeMobile2ErrorModal}/></div>}
 </div>

         
 <div className='hidden max-lg:block w-[100%]'>
<div className=''>
<div className='w-[100%] text-center p-2'>
<p style={{position: "fixed", top: "15%", left:"5%", zIndex:"3000" }} className='text-[10px] text-center text-white'>Already have an account? <NavLink to='/signin'><strong className='text-[#F6BD63]'>Sign in</strong></NavLink></p>
<img className='' src='https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png' alt='model'
/>
   <div className=' text-white'>
     <h1 className=' text-xl m-4'><strong>Campusify</strong></h1>
     <p className='my-2'>Sign up today and unlock a world of possiblities. Your adventure begins here.</p>
   </div>
 </div>
<div className='bg-[#0C0908] w-[100%] py-[1rem] px-[2rem] text-white  '>

<div className='flex flex-col gap-1 justify-center items-center'>
<Formik
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                mobile: '',
                mobile2: '',
                university: '',
                sex: '',
                address: ''
              }}
              validationSchema={SignupSchema}
              onSubmit={createAccount}
            >
                {({ isSubmitting }) => (

 <Form  className='p-1  w-full flex flex-col gap-1.5 mt-[1rem]'>
  
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='First Name'><strong>First Name</strong></label>
     <Field type='text' name='firstname' className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='First Name'/>
     <ErrorMessage name='firstname' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Last Name'><strong>Last Name</strong></label>
     <Field type='text' name='lastname' className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Last Name'/>
       <ErrorMessage name='lastname' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Email Address'><strong>Email Address</strong></label>
     <Field type='text' name='email' autoComplete="off" className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Email Address'/>
       <ErrorMessage name='email' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px]  '>
     <label className='text-bold' htmlFor='Password'><strong> Password</strong></label>
     <div className='relative rounded-[6px]  signup-border bg-[#F6BD63] text-black '>
     <Field type={passwordVisibility? "text" : "password" } name='password' className='p-2 w-[100%] rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Password'/>
     <div style={{left: "85%", top: "40%"}} className='absolute' onClick={togglePassword}>{renderComponent()}</div>
     </div>
     <ErrorMessage name='password' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Phone Number'><strong>Phone Number</strong></label>
     <Field type='text' name='mobile' autoComplete="off" className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Phone Number'/>
       <ErrorMessage name='mobile' component='div' className='text-red-500' />
   </div>
   <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Phone Number'><strong>Phone Number 2</strong></label>
     <Field type='text' name='mobile2' autoComplete="off" className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Second Phone Number'/>
       <ErrorMessage name='mobile2' component='div' className='text-red-500' />
   </div>
   <div className="col-span-2">
  <label className="font-bold">University</label>
  <Field name="university">
    {({ field, form }) => (
      <Select
        options={schools}
        styles={customStyles}
        placeholder="Select School"
        isClearable
        value={schools.find(option => option.value === field.value) || null}
        onChange={(option) => form.setFieldValue(field.name, option ? option.value : '')}
      />
    )}
  </Field>
  <ErrorMessage name="university" component="div" className="text-red-500 text-sm" />
</div>



<div className="col-span-2">
  <label className="font-bold">Gender</label>
  <Field name="sex">
    {({ field, form }) => (
      <Select
        options={sexOptions}
        styles={customStyles}
        placeholder="Select Gender"
        value={sexOptions.find(option => option.value === field.value) || null}
        onChange={(option) => form.setFieldValue(field.name, option ? option.value : '')}
      />
    )}
  </Field>
  <ErrorMessage name="sex" component="div" className="text-red-500 text-sm" />
</div>

                  <div className='flex flex-col gap-1  text-[12px] '>
     <label className='text-bold' htmlFor='Address'><strong>Address</strong></label>
     <Field type='text' name='address' className='p-2 rounded-[6px] signup-border bg-[#F6BD63] text-black' required placeholder='Address'/>
     <ErrorMessage name='address' component='div' className='text-red-500' />
   </div>
   
   <NavLink to='/forgotpassword' disabled={isSubmitting} className='text-[12px]'>Forgot Password ?</NavLink>

   <button type="submit" className='p-2 inline-block w-full rounded-[6px] mt-2 bg-black border-2 border-[#FFD700] text-[#FFB04A]'>Submit</button>
 </Form>
)}
</Formik>
 </div>
 <div className='text-xl text-center my-5'><Link  to='/contact'><strong>Send us a Message</strong></Link></div>
 </div>
 
 </div>


 <div  style={{position: "fixed", top: "30%", left:"50%"}}>
  {loading && <div className='loading-modal flex flex-col justify-center items-center'>    
  <p style={{ color: 'white', marginTop: '10px' }}>Creating account. Please wait...</p>
 <div className='w-[70%] my-[6rem]'><Bigloader /></div>
   </div>}
 </div>
   {createAccountModals && <div style={modal}><Createaccountsuccessful  closeModal={closeCreateAccountModal}/></div>}
   {createAccountErrorModals && <div style={modal}><Useralreadyexists closeModal={closeCreateAccountErrorModal}/></div>}
   {mobileErrorModal && <div style={modal}><Createaccountmobileerror closeModal={closeMobileErrorModal}/></div>}
   {mobile2ErrorModal && <div style={modal}><Createaccountmobile2error closeModal={closeMobile2ErrorModal}/></div>}
   <div><Footer/></div>
 </div>
         {createAccountModals && <div className=' border p-4'><Createaccountsuccessful/></div>}
         {createAccountErrorModals && <div className=' border p-4'><Useralreadyexists/></div>}
     </div>
  );
};

export const Signin = () => {
  const mainBG = {
    backgroundImage: '  url("https://res.cloudinary.com/dtthdh8tb/image/upload/v1728300443/Rectangle_242_k4pexq.png")   ',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
  };

  const modal = {
    position: 'fixed',
    top: '10%',
    right: '5%',
    zIndex: '4000',
    width: '60%',
  };

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginModals, setLoginModals] = useState(false);
  const [unexistingUserModals, setUnexistingUserModals] = useState(false);
  const [unauthorizedModals, setUnauthorizedModals] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loginAccountOrAds, setLoginAccountOrAds] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();
  const LOGIN_API_KEY = 'https://campusbuy-backend.onrender.com/login';

  const showPassword = () => setPasswordVisibility(true);
  const hidePassword = () => setPasswordVisibility(false);

  const togglePassword = () => {
    if (passwordVisibility) {
      hidePassword();
    } else {
      showPassword();
    }
  };

  const renderComponent = () => {
    return passwordVisibility ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />;
  };

  const handleEmailValidation = () => setEmailValidation(true);
  const handlePasswordValidation = () => setPasswordValidation(true);
  const closeEmailValidation = () => setEmailValidation(false);
  const closePasswordValidation = () => setPasswordValidation(false);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  
    if (name === 'email') {
      if (!value.includes('@') || value.includes(' ')) {
        // Check for '@' and spaces
        handleEmailValidation();
      } else {
        closeEmailValidation();
      }
    }
  
    if (name === 'password') {
      const passwordPattern = /^[a-zA-Z0-9]*$/;
  
      if (value.includes(' ')) {
        // Check for spaces in the password
        handlePasswordValidation();
      } else if (!passwordPattern.test(value)) {
        handlePasswordValidation();
      } else if (value.length < 8) {
        handlePasswordValidation();
      } else {
        closePasswordValidation();
      }
    }
  };
  

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(LOGIN_API_KEY, formData);
      if (response.data) {
        localStorage.setItem('userData', JSON.stringify(response.data));
        setLoginModals(true);
        setTimeout(() => {
          setLoginModals(false);
          navigate('/');
        }, 3000);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        setUnexistingUserModals(true);
        setTimeout(() => {
          setUnexistingUserModals(false);
          navigate('/signup');
        }, 4000);
      } else if (error.response?.status === 401) {
        setUnauthorizedModals(true);
        setTimeout(() => setUnauthorizedModals(false), 3000);
      } else {
        alert('Connection error. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const openAds = (e) => {
    // Open the external ad link in a new tab using window.open()
    e.preventDefault();
    setLoginAccountOrAds(false)
    window.open('https://psolsumoo.net/4/8022756', '_blank');
  };
  return (
    <div className="" style={mainBG}>
      <Secondheader />
      <div className="max-lg:hidden w-[100%]">
        <div className="flex justify-between">
          <div className="bg-[#0C0908] w-[50%] py-[1rem] px-[2rem] text-white mt-[10rem]">
          <div className='flex justify-end items-center my-4 mr-[6rem] gap-20'>
  <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={60} alt='logo'/></NavLink>
  <p className=' text-[12px] text-center'>Don't have an account? <NavLink to='/signup'><strong>Sign up</strong></NavLink></p>
</div>
            <div className="text-center m-1">
              <h1 className="text-xl m-2"><strong>Welcome to Campusify</strong></h1>
              <p className="text-sm">Sign in today and unlock a world of possibilities. Your adventure begins here.</p>
            </div>
            <form onSubmit={handleLogin} className="flex flex-col gap-2 mt-4">
              <div className='flex flex-col gap-2 m-2 p-2'>
                <label>Email Address</label>
                <input
                  type='text'
                  placeholder='Email Address'
                  value={formData.email}
                  name='email'
                  className="p-2 w-full rounded signup-border bg-[#F6BD63] text-black"
                  onChange={handleChange}
                />
                {emailValidation && <strong className='text-sm mx-2 text-[#8B0000]'>Invalid email address!</strong>}
              </div>

              <div className='flex flex-col gap-2 m-2 p-2'>
                <label>Password</label>
                <div className="relative">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    placeholder='Password'
                    value={formData.password}
                    name='password'
                    className="p-2 w-full rounded signup-border bg-[#F6BD63] text-black"
                    onChange={handleChange}
                  />
                  <div style={{ left: "85%", top: "40%" }} className='absolute text-black' onClick={togglePassword}>
                    {renderComponent()}
                  </div>
                </div>
                {passwordValidation && <strong className='text-sm mx-2 text-[#8B0000]'>Password must be more than 8 characters and contain only letters and digits.</strong>}
              </div>

              <NavLink to="/forgotpassword" className="text-xs">Forgot Password?</NavLink>

              <button
                type="submit"
                className="p-2 bg-black border-2 border-[#FFD700] text-[#FFB04A] w-full rounded mt-2"
              >
                Submit
              </button>
            </form>
          </div>

          <div className="w-[50%] text-center p-2">
            <div className="text-white mt-[12rem]">
              <h1 className="text-2xl m-4"><strong>Campusify</strong></h1>
              <p className="my-2">For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc.</p>
            </div>
            <img
              src="https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png"
              alt="model"
            />
          </div>
        </div>
      </div>
      <div className='hidden max-lg:block w-full'>
        <div className='bg-[#0C0908] w-full py-4 px-4 text-white'>
          <div className='flex justify-between items-center mb-4 mt-[4rem]'>
            <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={50} alt='logo'/></NavLink>
            <p className='text-[10px] text-center'>Don't have an account? <NavLink to='/signup'><strong className='text-[#F6BD63]'>Sign up</strong></NavLink></p>
          </div>
          
          <div className="text-center mb-4">
            <h1 className="text-lg m-2"><strong>Welcome to Campusify</strong></h1>
            <p className="text-xs">Sign in today and unlock a world of possibilities. Your adventure begins here.</p>
          </div>
          <div className='text-center my-4'>
          <img
            src="https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png"
            alt="model"
            className='mx-auto'
          />
          <div className="text-white mt-4">
            <h1 className="text-lg m-2"><strong>Campusify</strong></h1>
            <p className="text-sm my-2">For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc.</p>
          </div>
        </div>
          <form onSubmit={handleLogin} className="flex flex-col gap-2">
            <div className='flex flex-col gap-1 m-2 p-2'>
              <label className='text-xs font-bold'>Email Address</label>
              <input
                type='text'
                placeholder='Email Address'
                value={formData.email}
                name='email'
                className="p-2 rounded bg-[#F6BD63] text-black"
                onChange={handleChange}
              />
              {emailValidation && <strong className='text-xs mx-2 text-[#8B0000]'>Invalid email address or space in email address!</strong>}
            </div>

            <div className='flex flex-col gap-1 m-2 p-2'>
              <label className='text-xs font-bold'>Password</label>
              <div className="relative">
                <input
                  type={passwordVisibility ? "text" : "password"}
                  placeholder='Password'
                  value={formData.password}
                  name='password'
                  className="p-2 w-full rounded bg-[#F6BD63] text-black"
                  onChange={handleChange}
                />
                <div style={{ left: "85%", top: "40%" }} className='absolute text-black' onClick={togglePassword}>
                  {renderComponent()}
                </div>
              </div>
              {passwordValidation && <strong className='text-xs mx-2 text-[#8B0000]'>Password must be more than 8 characters and contain only letters and digits; No symbols or spaces allowed.</strong>}
            </div>

            <NavLink to="/forgotpassword" className="text-[10px]">Forgot Password?</NavLink>

            <button
              type="submit"
              className="p-2 bg-black border-2 border-[#FFD700] text-[#FFB04A] w-full rounded mt-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      {loading && (
        <div className="fixed w-[60%] top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <div className="loading-modal flex flex-col items-center">
            <p className="text-white mb-2">Logging in. Please wait...</p>
            <Bigloader />
          </div>
        </div>
      )}      
      {loginModals && <div style={modal}><Loginsuccessful closeModal={() => setLoginModals(false)} /></div>}
      {unauthorizedModals && <div style={modal}><Incorrectpassword closeModal={() => setUnauthorizedModals(false)} /></div>}
      {unexistingUserModals && <div style={modal}><Userdoesnotexist closeModal={() => setUnexistingUserModals(false)} /></div>}
      <div><Footer/></div>
    </div>
  );
};

export const Forgotpassword = () => {
  const mainBG = {
    backgroundImage: '  url("https://res.cloudinary.com/dtthdh8tb/image/upload/v1728300443/Rectangle_242_k4pexq.png")   ',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
  };

  const modal = {
    position: 'fixed',
    top: '10%',
    right: '5%',
    zIndex: '4000',
    width: '60%',
  };

  const [loading, setLoading] = useState(false); // Loading state
  const [activeTip, setActiveTip] = useState(null);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [unexistingUserModals, setUnexistingUserModals] = useState(false);
  const navigate = useNavigate();
  const [emailValidation, setEmailValidation] = useState(false);
  const [forgotPasswordOrAds, setForgotPasswordOrAds] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
  });
  const handleEmailValidation = () => setEmailValidation(true);
  const closeEmailValidation = () => setEmailValidation(false);

  const tipsArray = ['Pricetips', 'Purchasetips', 'Bulkgoodstips'];
  const getRandomTip = () => tipsArray[Math.floor(Math.random() * tipsArray.length)];

  useEffect(() => {
    const displayRandomTip = () => {
      setActiveTip(getRandomTip());
    };

    const displayTipTimeout = setTimeout(displayRandomTip, 50000);
    const changeTipInterval = setInterval(displayRandomTip, 600000);

    return () => {
      clearTimeout(displayTipTimeout);
      clearInterval(changeTipInterval);
    };
  }, []);

  const closeTips = () => setActiveTip(null);

  let tipComponent = null;
  if (activeTip === 'Pricetips') {
    tipComponent = <Pricetips closeTips={closeTips} />;
  } else if (activeTip === 'Purchasetips') {
    tipComponent = <Purchasetips closeTips={closeTips} />;
  } else if (activeTip === 'Bulkgoodstips') {
    tipComponent = <Bulkgoodstips closeTips={closeTips} />;
  }

  const openForgotPasswordModal = () => setForgotPasswordModal(true);
  const closeForgotPasswordModal = () => setForgotPasswordModal(false);
  const openUnexistingUserModal = () => setUnexistingUserModals(true);
  const closeUnexistingUserModal = () => setUnexistingUserModals(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      if (!value.includes('@')) {
        handleEmailValidation();
      } else {
        closeEmailValidation();
      }
    }
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    openLoading();

    try {
      // const formData = new FormData(e.target);
      // const userData = {};
      // formData.forEach((value, key) => (userData[key] = value));

      const response = await axios.post('https://campusbuy-backend.onrender.com/forgotpassword', formData);

      if (response.data) {
        openForgotPasswordModal();
        e.target.reset();
        setTimeout(closeForgotPasswordModal, 5000);
      } else {
        alert('Connection error. Please refresh your network');
      }
    } catch (error) {
      if (error.response?.status === 401) {
        openUnexistingUserModal();
        setTimeout(() => {
          closeUnexistingUserModal();
          navigate('/signup');
        }, 4000);
      } else {
        alert('Connection error. Please refresh your network');
        console.error('Error resetting your password:', error);
      }
    } finally {
      closeLoading();
    }
  };

  const openAds = (e) => {
    // Open the external ad link in a new tab using window.open()
    e.preventDefault();
    setForgotPasswordOrAds(false)
    window.open('https://psolsumoo.net/4/8022756', '_blank');
  };
  const openLoading = () => setLoading(true);
  const closeLoading = () => setLoading(false);

  return (
    <div className="w-[100%]" style={mainBG}>
      <Helmet>
        <meta property="og:url" content="https://campusify.net/forgotpassword" />
        <meta property="og:title" content="Forgot Password: Retrieve your password" />
        <meta
          property="og:description"
          content="The largest student-to-student online marketplace in Nigeria. Buy and sell anything from your fellow students. Textbooks, electronics, clothes, and more! Safe and secure platform with guaranteed delivery."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298881/IMG-20240227-WA0015_wdparn.jpg"
        />
        <meta property="og:site_name" content="Campusify" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="w-[15%] max-lg:w-[20%] all-round-tips rounded-lg bg-[rgba(255, 255, 255, 0.8)]">
        {tipComponent}
      </div>
      <div className="">
        <Secondheader />
      </div>
      <div className="max-lg:hidden w-[100%]">
        <div className="flex justify-between">
          <div className="bg-[#0C0908] w-[50%] py-[1rem] px-[2rem] text-white mt-[10rem]">
          <div className='flex justify-end items-center my-4 mr-[6rem] gap-20'>
  <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={60} alt='logo'/></NavLink>
  <p className=' text-[12px] text-center'>Don't have an account? <NavLink to='/signup'><strong>Sign up</strong></NavLink></p>
</div>
            <div className="text-center m-1">
              <h1 className="text-xl m-2"><strong>Welcome to Campusify</strong></h1>
              <p className="text-lg">Input your email to retrieve your password.</p>
              </div>
            <form onSubmit={forgotPassword} className="flex flex-col gap-2 mt-4">
              <div className='flex flex-col gap-2 m-2 p-2'>
                <label>Email Address</label>
                <input
                  type='text'
                  placeholder='Email Address'
                  value={formData.email}
                  name='email'
                  className="p-2 w-full rounded signup-border bg-[#F6BD63] text-black"
                  onChange={handleChange}
                />
                {emailValidation && <strong className='text-sm mx-2 text-[#8B0000]'>Invalid email address!</strong>}
              </div>
              <NavLink to="/signin" className="text-xs">Sign in</NavLink>

              <button
                type="submit"
                className="p-2 bg-black border-2 border-[#FFD700] text-[#FFB04A] w-full rounded mt-2"
              >
                Submit
              </button>
            </form>
          </div>

          <div className="w-[50%] text-center p-2">
            <div className="text-white mt-[12rem]">
              <h1 className="text-2xl m-4"><strong>Campusify</strong></h1>
              <p className="my-2">For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc.</p>
            </div>
            <img
              src="https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png"
              alt="model"
            />
          </div>
        </div>
      </div>
      <div className='hidden max-lg:block w-full'>
        <div className='bg-[#0C0908] w-full py-4 px-4 text-white'>
          <div className='flex justify-between items-center mb-4 mt-[4rem]'>
            <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={50} alt='logo'/></NavLink>
            <p className='text-[10px] text-center'>Don't have an account? <NavLink to='/signup'><strong className='text-[#F6BD63]'>Sign up</strong></NavLink></p>
          </div>
          
          <div className="text-center mb-4">
            <h1 className="text-lg m-2"><strong>Welcome to Campusify</strong></h1>
            <p className="text-lg">Input your email to retrieve your password.</p>
          </div>
          <div className='text-center my-4'>
          <img
            src="https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png"
            alt="model"
            className='mx-auto'
          />
          <div className="text-white mt-4">
            <h1 className="text-lg m-2"><strong>Campusify</strong></h1>
            <p className="text-sm my-2">For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc.</p>
          </div>
        </div>
          <form onSubmit={forgotPassword} className="flex flex-col gap-2">
            <div className='flex flex-col gap-1 m-2 p-2'>
              <label className='text-xs font-bold'>Email Address</label>
              <input
                type='text'
                placeholder='Email Address'
                value={formData.email}
                name='email'
                className="p-2 rounded bg-[#F6BD63] text-black"
                onChange={handleChange}
              />
              {emailValidation && <strong className='text-xs mx-2 text-[#8B0000]'>Invalid email address!</strong>}
            </div>
            <NavLink to="/signin" className="text-[10px]">Sign in</NavLink>

            <button
              type="submit"
              className="p-2 bg-black border-2 border-[#FFD700] text-[#FFB04A] w-full rounded mt-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div  style={{position: "fixed", top: "30%", left:"50%"}}>
   {loading && <div className='loading-modal flex flex-col justify-center items-center'>    
   <p style={{ color: 'white', marginTop: '10px' }}>Sending email. Please wait...</p>
  <div className='w-[70%] my-[6rem] ml-[6rem]'><Bigloader  /></div>
    </div>}
  </div>
   {forgotPasswordModal && <div  style={modal}><Resetpasswordemail closeModal={closeForgotPasswordModal}/></div>}
   {unexistingUserModals && <div  style={modal}><Userdoesnotexist closeModal={closeUnexistingUserModal}/></div>}
      <Footer />
    </div>
  );
};

export const Resetpassword = () => {
  const mainBG = {
    backgroundImage: '  url("https://res.cloudinary.com/dtthdh8tb/image/upload/v1728300443/Rectangle_242_k4pexq.png")   ',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
  };

  const modal = {
    position: 'fixed',
    top: '10%',
    right: '5%',
    zIndex: '4000',
    width: '60%',
  };

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginModals, setLoginModals] = useState(false);
  const [unexistingUserModals, setUnexistingUserModals] = useState(false);
  const [unauthorizedModals, setUnauthorizedModals] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [resetPasswordOrAds, setResetPasswordOrAds] = useState(false);
  const FINDUSER_API_KEY = 'https://campusbuy-backend.onrender.com/finduser';
  const RESETPASSWORD_API_KEY = 'https://campusbuy-backend.onrender.com/resetpassword';
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const showPassword = () => setPasswordVisibility(true);
  const hidePassword = () => setPasswordVisibility(false);

  const togglePassword = () => {
    if (passwordVisibility) {
      hidePassword();
    } else {
      showPassword();
    }
  };

  const renderComponent = () => {
    return passwordVisibility ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />;
  };

  const handleEmailValidation = () => setEmailValidation(true);
  const handlePasswordValidation = () => setPasswordValidation(true);
  const closeEmailValidation = () => setEmailValidation(false);
  const closePasswordValidation = () => setPasswordValidation(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      if (!value.includes('@')) {
        handleEmailValidation();
      } else {
        closeEmailValidation();
      }
    }

    if (name === 'password') {
      const passwordPattern = /^[a-zA-Z0-9]*$/;
      if (!passwordPattern.test(value) || value.length < 8) {
        handlePasswordValidation();
      } else {
        closePasswordValidation();
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userResponse = await axios.post(FINDUSER_API_KEY, formData);
      const user = userResponse.data;
      const { resetToken } = user;

      if (!resetToken) throw new Error('Password reset token not found');

      const response = await axios.put(`${RESETPASSWORD_API_KEY}/${resetToken}`, formData);
      if (response) {
        setLoginModals(true);
        setTimeout(() => {
          setLoginModals(false);
          navigate('/signin');
        }, 5000);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setUnauthorizedModals(true);
          setTimeout(() => setUnauthorizedModals(false), 3000);
        } else {
          alert('Connection error. Please refresh your network.');
        }
      } else {
        alert('Connection error. Please refresh your network.');
      }
    } finally {
      setLoading(false);
    }
  };
  const openAds = (e) => {
    // Open the external ad link in a new tab using window.open()
    e.preventDefault();
    setResetPasswordOrAds(false)
    window.open('https://psolsumoo.net/4/8022756', '_blank');
  };

  return (
    <div className="" style={mainBG}>
      <Secondheader />
      <div className="max-lg:hidden w-[100%]">
        <div className="flex justify-between">
          <div className="bg-[#0C0908] w-[50%] py-[1rem] px-[2rem] text-white mt-[10rem]">
            <div className='flex justify-end items-center my-4 mr-[6rem] gap-20'>
              <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={60} alt='logo'/></NavLink>
              <p className=' text-[12px] text-center'>Don't have an account? <NavLink to='/signup'><strong>Sign up</strong></NavLink></p>
            </div>
            <div className="text-center m-1">
              <h1 className="text-xl m-2"><strong>Welcome to Campusify</strong></h1>
              <p className="text-sm">Sign in today and unlock a world of possibilities. Your adventure begins here.</p>
            </div>
            <form onSubmit={handleLogin} className="flex flex-col gap-2 mt-4">
              <div className='flex flex-col gap-2 m-2 p-2'>
                <label>Email Address</label>
                <input
                  type='text'
                  placeholder='Email Address'
                  value={formData.email}
                  name='email'
                  className="p-2 w-full rounded signup-border bg-[#F6BD63] text-black"
                  onChange={handleChange}
                />
                {emailValidation && <strong className='text-sm mx-2 text-[#8B0000]'>Invalid email address!</strong>}
              </div>

              <div className='flex flex-col gap-2 m-2 p-2'>
                <label>Password</label>
                <div className="relative">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    placeholder='Password'
                    value={formData.password}
                    name='password'
                    className="p-2 w-full rounded signup-border bg-[#F6BD63] text-black"
                    onChange={handleChange}
                  />
                  <div style={{ left: "85%", top: "40%" }} className='absolute text-black' onClick={togglePassword}>
                    {renderComponent()}
                  </div>
                </div>
                {passwordValidation && <strong className='text-sm mx-2 text-[#8B0000]'>Password must be more than 8 characters and contain only letters and digits.</strong>}
              </div>

              <NavLink to="/forgotpassword" className="text-xs">Forgot Password?</NavLink>

              <button
                type="submit"
                className="p-2 bg-black border-2 border-[#FFD700] text-[#FFB04A] w-full rounded mt-2"
              >
                Submit
              </button>
            </form>
          </div>

          <div className="w-[50%] text-center p-2">
            <div className="text-white mt-[12rem]">
              <h1 className="text-2xl m-4"><strong>Campusify</strong></h1>
              <p className="my-2">For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc.</p>
            </div>
            <img
              src="https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png"
              alt="model"
            />
          </div>
        </div>
      </div>
      <div className='hidden max-lg:block w-full'>
        <div className='bg-[#0C0908] w-full py-4 px-4 text-white'>
          <div className='flex justify-between items-center mb-4 mt-[4rem]'>
            <NavLink to='/'><img src='https://res.cloudinary.com/dtthdh8tb/image/upload/v1728298440/logo_erit8k.png' width={50} alt='logo'/></NavLink>
            <p className='text-[10px] text-center'>Don't have an account? <NavLink to='/signup'><strong className='text-[#F6BD63]'>Sign up</strong></NavLink></p>
          </div>
          
          <div className="text-center mb-4">
            <h1 className="text-lg m-2"><strong>Welcome to Campusify</strong></h1>
            <p className="text-xs">Sign in today and unlock a world of possibilities. Your adventure begins here.</p>
          </div>
          <div className='text-center my-4'>
          <img
            src="https://res.cloudinary.com/djj8xwuzn/image/upload/v1707425271/Default/Rectangle_318-removebg-preview_b9sdli.png"
            alt="model"
            className='mx-auto'
          />
          <div className="text-white mt-4">
            <h1 className="text-lg m-2"><strong>Campusify</strong></h1>
            <p className="text-xs my-2">For all your campus needs; Utensils, Mattresses, Textbooks, Stationeries, Gadgets etc.</p>
          </div>
          </div>
          <form onSubmit={handleLogin} className="flex flex-col gap-2">
            <div className='flex flex-col gap-1 m-2 p-2'>
              <label className='text-xs font-bold'>Email Address</label>
              <input
                type='text'
                placeholder='Email Address'
                value={formData.email}
                name='email'
                className="p-2 rounded bg-[#F6BD63] text-black"
                onChange={handleChange}
              />
              {emailValidation && <strong className='text-xs mx-2 text-[#8B0000]'>Invalid email address!</strong>}
            </div>

            <div className='flex flex-col gap-1 m-2 p-2'>
              <label className='text-xs font-bold'>Password</label>
              <div className="relative">
                <input
                  type={passwordVisibility ? "text" : "password"}
                  placeholder='Password'
                  value={formData.password}
                  name='password'
                  className="p-2 w-full rounded bg-[#F6BD63] text-black"
                  onChange={handleChange}
                />
                <div style={{ left: "85%", top: "40%" }} className='absolute text-black' onClick={togglePassword}>
                  {renderComponent()}
                </div>
              </div>
              {passwordValidation && <strong className='text-xs mx-2 text-[#8B0000]'>Password must be more than 8 characters and contain only letters and digits.</strong>}
            </div>

            <NavLink to="/forgotpassword" className="text-[10px]">Forgot Password?</NavLink>

            <button
              type="submit"
              className="p-2 bg-black border-2 border-[#FFD700] text-[#FFB04A] w-full rounded mt-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* Modals */}
      <div  style={{position: "fixed", top: "30%", left:"50%"}}>
   {loading && <div className='loading-modal flex flex-col justify-center items-center'>    
   <p style={{ color: 'white', marginTop: '10px' }}>Resetting password. Please wait...</p>
  <div className='w-[70%] my-[6rem]'><Bigloader 
    /></div>
    </div>}
  </div>
   {loginModals && <div style={modal}><Resetpasswordsuccessful closeModal={()=>{setLoginModals(false)}}/></div>}
   {unauthorizedModals && <div style={modal}><Resetpasswordlinkexpired closeModal={()=>{setUnauthorizedModals(false)}}/></div>}
   <div><Footer/></div>
    </div>
  );
};

